import {
  AccountCircle,
  AttachMoney,
  Bolt,
  Cancel,
  ContentCopy,
  Description,
  Edit,
  FileUpload,
  Savings,
  Settings,
  TableChart,
  TrendingUp,
  Warning,
} from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ProjectsContext } from "../data/contexts/ProjectsContext";
import { SettingsContext } from "../data/contexts/SettingsContext";
import { TokenContext } from "../data/contexts/TokenContext";
import { dateConvertToString, formatCapacity, formatNumber, formatSiret } from "../data/dataConvertors";
import {
  Operation,
  OperationInput,
  ProcedureStatus,
  ProjectStatus,
  ProjectType,
  RateStatus,
  ValidationStatus,
} from "../data/generated/graphql";
import BaseCard from "../utils/BaseCard";
import CenteredContent from "../utils/CenteredContent";
import GdAlert from "../utils/GdAlert";
import GdButton from "../utils/GdButton";
import GdDataTable, { DataTableLine } from "../utils/GdDataTable";
import GdList from "../utils/GdList";
import StatusLabel from "../utils/StatusLabel";
import exportData from "../utils/exportData";
import { Lvl, log } from "../utils/log";
import DocumentsViewer from "./DocumentsViewer";
import "./Project.css";
import { dontTouchComputationOnProject, isProjectObsolete } from "./ProjectHelper";
import SubProjectAccordion from "./SubProject";
import AmountsAndDatesModal from "./components/AmountsAndDatesModal";
import ProjectCancelModal from "./components/ProjectCancelModal";
import ProjectComments from "./components/ProjectComments";
import ProjectDateModal from "./components/ProjectDateModal";
import ProjectUsers from "./components/ProjectUsers";
import ForceRateButton from "./form/ForceRateButton";
import OperationsViewer from "./form/OperationsViewer";

enum NextManualStep {
  NONE = "NONE",
  OPEN = "OPEN",
  SENT = "SENT",
  APPROVED = "APPROVED",
  INVSENT = "INVSENT",
  OBLIGED_PAID = "OBLIGED_PAID",
  PAID = "PAID",
}

const Project: FC = () => {
  const [userOpen, setUserOpen] = useState(false);
  const [operationsOpen, setOperationsOpen] = useState(false);
  const [affectObligedOpen, setAffectObligedOpen] = useState(false);
  const [affectingObliged, setAffectingObliged] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const { projectId } = useParams() as { projectId: string };
  const { projects, getProjectDetails, affectObliged, updateProject, updateFlags, getSubProjects, subProjects } =
    useContext(ProjectsContext);
  const { settings } = useContext(SettingsContext);
  const { getToken } = useContext(TokenContext);
  const project = projects.find((p) => p.id === projectId);
  const [loading, setLoading] = useState(typeof project === "undefined");
  const { t } = useTranslation(["project", "global"]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedObligedId, setSelectedObligedId] = useState(project?.obligedId || "");
  const [reference, setReference] = useState(project?.reference || "");
  const [changeRefOpen, setChangeRefOpen] = useState(false);
  const [changingRef, setChangingRef] = useState(false);
  const [switchingToNextStep, setSwitchingToNextStep] = useState(false);
  const [changingTest, setChangingTest] = useState(false);
  const [changingStandby, setChangingStandby] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);
  const [projectDateOpen, setProjectDateOpen] = useState(false);
  const [exportingPnceeCsv, setExportingPnceeCsv] = useState(false);
  const [exportingTableSummary, setExportingTableSummary] = useState(false);
  const [subProjectsIsLoading, setSubProjectsIsLoading] = useState(false);
  const isVehicleFleet = project?.projectType === ProjectType.VehicleFleet;
  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getProjectDetails(projectId);
      if (!result) log("Error while getting project details", Lvl.ERROR, { message: "No result" });
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (project?.obligedId) setSelectedObligedId(project.obligedId);
    if (isVehicleFleet) {
      setSubProjectsIsLoading(true);
      (async () => {
        await getSubProjects(projectId);
        setSubProjectsIsLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);
  if (!project) {
    if (loading) return <CenteredContent loading />;
    return (
      <BaseCard>
        <Typography>{t("projectNotFound")}</Typography>
      </BaseCard>
    );
  }

  const showOperations = (): void => {
    setOperationsOpen(true);
  };

  const clientData: DataTableLine[] = project.client
    ? [
        { label: t("companyTwo"), data: project.client?.company?.name },
        { label: t("siretTwo"), data: formatSiret(project.client?.company?.siret) },
        {
          label: t("addressTwo"),
          data: (
            <>
              <Typography>{project.client?.company?.address}</Typography>
              <Typography>
                {project.client?.company?.zipCode} {project.client?.company?.city}
              </Typography>
            </>
          ),
        },
      ]
    : [];

  const detailsData: DataTableLine[] = project.details
    ? [
        {
          label: t("datesTwo"),
          data: `du ${dateConvertToString(project.details?.startDate)} au ${dateConvertToString(
            project.details?.endDate,
          )}`,
        },
        {
          label: t("sectorTwo"),
          data: t(`sectors.${project.details?.sector}`),
        },
        {
          label: t("cpeTwo"),
          data: project.details?.cpePercentage
            ? t("cpeWithDurationAndPrecentage", {
                replace: { percentage: project.details?.cpePercentage, duration: project.details?.cpeDuration },
              })
            : t("global:yesOrNo.no"),
        },
      ]
    : [];
  if (project.quote?.validation?.status === ValidationStatus.Accepted)
    detailsData.push({
      label: t("quoteAmountTwo"),
      data: t("amountAndDate", {
        replace: {
          date: dateConvertToString(project.quote?.date || "A renseigner"),
          amount: formatNumber(project.quote?.amount || "A renseigner"),
        },
      }),
    });

  const isProjectCapacityValid =
    project.totalComputation?.tooHigh !== true && project.totalComputation?.tooLow !== true;
  const invalidLabel = project.totalComputation?.tooHigh ? t("thisProjectIsTooBig") : t("thisProjectIsTooSmall");

  const getObligedName = (obligedId: string): string =>
    settings.obliged.find((o) => o.id === obligedId)?.name || "Not found";

  const affectProjectToObliged = async (): Promise<void> => {
    setAffectingObliged(true);
    const result = await affectObliged(project.id, selectedObligedId);
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    }
    setAffectingObliged(false);
    setAffectObligedOpen(false);
  };
  const activeObliged = settings.obliged.filter((o) => o.active);

  const isProjectCanceled = project.status === ProjectStatus.Canceled;
  const isPreworkFinished =
    project.quote?.validation?.status === ValidationStatus.Accepted ||
    project.convention?.status === ProcedureStatus.Finished;
  const canCancel = !project.quote || project.quote?.validation?.status !== ValidationStatus.Accepted;

  const changeRef = async (): Promise<void> => {
    setChangingRef(true);
    try {
      const result = await updateProject({ id: project.id, reference });
      if (!result) {
        enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
      } else {
        enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
        setChangeRefOpen(false);
      }
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: "error" });
    }
    setChangingRef(false);
  };

  let nextManualStep = NextManualStep.NONE;
  if (project.status === ProjectStatus.PostworkCompleted && !project.flags?.isSent)
    nextManualStep = NextManualStep.SENT;
  else if (project.status === ProjectStatus.PostworkCompleted && !project.flags?.isApproved)
    nextManualStep = NextManualStep.APPROVED;
  else if (project.status === ProjectStatus.PostworkCompleted && project.flags?.isSent && !project.flags?.isInvoiceSent)
    nextManualStep = NextManualStep.INVSENT;
  else if (
    (project.status === ProjectStatus.PostworkCompleted || project.status === ProjectStatus.Finished) &&
    !project.flags?.isObligedPaid
  )
    nextManualStep = NextManualStep.OBLIGED_PAID;
  else if (
    (project.status === ProjectStatus.PostworkCompleted || project.status === ProjectStatus.Finished) &&
    !project.flags?.isPaid
  )
    nextManualStep = NextManualStep.PAID;
  else if (project.status === ProjectStatus.PreworkCompleted && !project.flags?.isOpen)
    nextManualStep = NextManualStep.OPEN;

  const switchToNextManualStep = async (): Promise<void> => {
    setSwitchingToNextStep(true);
    try {
      const result = await updateFlags(
        project.id,
        nextManualStep === NextManualStep.PAID
          ? { isPaid: true }
          : nextManualStep === NextManualStep.SENT
          ? { isSent: true }
          : nextManualStep === NextManualStep.APPROVED
          ? { isApproved: true }
          : nextManualStep === NextManualStep.INVSENT
          ? { isInvoiceSent: true }
          : nextManualStep === NextManualStep.OBLIGED_PAID
          ? { isObligedPaid: true }
          : { isOpen: true },
      );
      if (!result) {
        enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
      } else {
        enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
      }
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: "error" });
    }
    setSwitchingToNextStep(false);
  };

  const changeTest = async (): Promise<void> => {
    setChangingTest(true);
    try {
      const result = await updateFlags(project.id, { isTest: !project.flags?.isTest });
      if (!result) {
        enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
      } else {
        enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
      }
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: "error" });
    }
    setChangingTest(false);
  };

  const changeStandby = async (): Promise<void> => {
    setChangingStandby(true);
    try {
      const result = await updateFlags(project.id, { isStandBy: !project.flags?.isStandBy });
      if (!result) {
        enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
      } else {
        enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
      }
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: "error" });
    }
    setChangingStandby(false);
  };

  const isObsolete = isProjectObsolete(project);
  const dontTouchOperations = dontTouchComputationOnProject(project);

  const replaceOperations = async (modifiedOperations: OperationInput[]): Promise<void> => {
    const result = await updateProject({ id: project.id, operations: modifiedOperations });

    if (!result) {
      log("Error while updating project", Lvl.ERROR);
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    }
  };

  const exportPnceeCsv = async (): Promise<void> => {
    setExportingPnceeCsv(true);
    const token = await getToken();
    if (token !== null)
      await exportData(
        "",
        token,
        (msg) => enqueueSnackbar(t("exportError", { replace: { msg } }), { variant: "error" }),
        undefined,
        undefined,
        true,
        project.id,
        isVehicleFleet,
      );
    setExportingPnceeCsv(false);
  };

  const exportTableSummary = async (): Promise<void> => {
    setExportingTableSummary(true);
    const token = await getToken();
    if (token !== null)
      await exportData(
        "",
        token,
        (msg) => enqueueSnackbar(t("exportError", { replace: { msg } }), { variant: "error" }),
        undefined,
        undefined,
        undefined,
        project.id,
        isVehicleFleet,
        true,
      );
    setExportingTableSummary(false);
  };

  return (
    <BaseCard>
      <div className="row-top space-between">
        <div style={{ flex: 1 }}>
          <div className="row margin-bottom">
            <Typography variant="h6">
              {t("project")} &quot;{project.name}&quot; (<StatusLabel status={project.detailedStatus} />)
            </Typography>
            {nextManualStep !== NextManualStep.NONE && !loading ? (
              <GdButton
                label={t(`nextManualStep.${nextManualStep}`)}
                className="margin-left"
                onClick={switchToNextManualStep}
                isLoading={switchingToNextStep}
              />
            ) : undefined}
            {isObsolete ? (
              <Tooltip title={t("isObsolete")} arrow>
                <Warning color="error" />
              </Tooltip>
            ) : undefined}
            <IconButton
              style={{ marginBottom: 8 }}
              onClick={() => {
                setAdminOpen(true);
              }}>
              <Settings />
            </IconButton>
          </div>
          {loading ? (
            <CenteredContent loading />
          ) : (
            <>
              <div className="row-top space-between">
                <div style={{ flex: 1 }}>
                  <Typography variant="h6" className="text-center margin-bottom">
                    {t("clientTwo")}
                  </Typography>
                  <GdDataTable data={clientData} />
                </div>
                <div style={{ width: 32 }} />
                <div style={{ flex: 1 }}>
                  <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px" }}
                    className="margin-bottom">
                    <Typography variant="h6" className="text-center">
                      {t("detailsTwo")}
                    </Typography>
                    <Tooltip arrow title={t("modifDates")}>
                      <IconButton onClick={() => setProjectDateOpen(true)} sx={{ padding: 0 }}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <GdDataTable data={detailsData} />
                </div>
              </div>
              <div className="row space-between margin-top">
                <GdList
                  fontSize="big"
                  items={[
                    {
                      content: t("capacityTwo", {
                        replace: { capacity: formatNumber(project.totalComputation?.capacity, 3, false) },
                      }),
                      icon: <Bolt />,
                    },
                    {
                      content: t("valuationTwo", {
                        replace: { valuation: formatNumber(project.totalComputation?.valuation, 2, true) },
                      }),
                      icon: <AttachMoney />,
                    },
                    {
                      content: `${t(project.userRate ? "rateTwoUserRate" : "rateTwo", {
                        replace: {
                          rate: formatNumber(project.totalComputation?.rate?.value || undefined, 2, true),
                          userRate: formatNumber(project.userRate || undefined, 2, true),
                        },
                      })}${
                        project.totalComputation?.rateIsManual
                          ? t(
                              project.totalComputation?.rate?.status === RateStatus.Official
                                ? "validManualRate"
                                : "invalidManualRate",
                            )
                          : ""
                      }`,
                      icon: <TrendingUp />,
                    },
                    project.invoiceInfo !== undefined &&
                    (project.invoiceInfo?.date?.length || 0) > 0 &&
                    (project.invoiceInfo?.amount?.length || 0) > 0
                      ? {
                          content: `${t("billTwo")} ${t("amountAndDate", {
                            replace: {
                              date: dateConvertToString(project.invoiceInfo?.date || ""),
                              amount: formatNumber(project.invoiceInfo?.amount || ""),
                            },
                          })}`,
                          icon: <Description />,
                        }
                      : { content: "" },
                    ...(typeof project.details?.cpePercentage === "string" &&
                    project.totalComputation?.cpeBonus &&
                    project.totalComputation?.cpeBonus !== "null" &&
                    project.totalComputation?.cpeBonus !== "undefined"
                      ? [
                          {
                            content: t("cpeBonusTwo", { replace: { bonus: project.totalComputation?.cpeBonus } }),
                            icon: <Savings />,
                          },
                        ]
                      : []),
                  ]}
                />
                <div className="column-right">
                  <Typography variant="h6" className="margin-bottom">
                    {t("operations", { count: project.operations?.length || 0 })}
                  </Typography>
                  <GdButton
                    label={t("showDetails")}
                    onClick={showOperations}
                    disabled={(project.operations?.length || 0) === 0}
                    color="secondary"
                    className="margin-bottom"
                  />
                  {isProjectCanceled || isPreworkFinished ? undefined : <ForceRateButton project={project} />}
                </div>
              </div>
              <div className="row space-between margin-top margin-bottom">
                <Typography variant="h6">
                  {project.obligedId
                    ? t("obligedTwo", { replace: { obliged: getObligedName(project.obligedId) } })
                    : t("noObligedYet")}
                </Typography>
                {project.convention || isProjectCanceled ? undefined : (
                  <div className="column-right">
                    <GdButton label={t("affectObliged")} onClick={() => setAffectObligedOpen(true)} />
                  </div>
                )}
              </div>
              <div className="row space-between margin-top margin-bottom">
                <Typography variant="h6">
                  {project.reference
                    ? t("projectReference", { replace: { reference: project.reference } })
                    : t("noProjectReferenceYet")}
                </Typography>
                {project.reference && project.convention?.status !== ProcedureStatus.Finished ? (
                  <div className="column-right">
                    <GdButton label={t("changeProjectReference")} onClick={() => setChangeRefOpen(true)} />
                    <GdAlert
                      open={changeRefOpen}
                      onClose={() => setChangeRefOpen(false)}
                      title={t("changeReference")}
                      body={<TextField value={reference} onChange={(e) => setReference(e.target.value)} />}
                      okButtonDisabled={reference.length === 0 || reference === project.reference}
                      okButtonClick={changeRef}
                      okButtonLoading={changingRef}
                    />
                  </div>
                ) : undefined}
              </div>
              {!isProjectCapacityValid || isProjectCanceled ? (
                <Typography variant="h6" className="margin-top">
                  {isProjectCanceled ? t("thisProjectIsCanceled") : invalidLabel}
                </Typography>
              ) : (
                <DocumentsViewer project={project} />
              )}
              {canCancel && !isProjectCanceled ? (
                <div className="row space-between margin-top">
                  <GdButton
                    label={t(`thisProjectIsATest${project.flags?.isTest ? "Cancel" : ""}`)}
                    onClick={changeTest}
                    color={project.flags?.isTest ? "inherit" : "info"}
                    isLoading={changingTest}
                  />
                  <GdButton label={t("cancelThisProject")} onClick={() => setCancelOpen(true)} color="error" />
                  <GdButton
                    label={t(`putThisProjectOnStandby${project.flags?.isStandBy ? "Cancel" : ""}`)}
                    onClick={changeStandby}
                    color={project.flags?.isStandBy ? "inherit" : "warning"}
                    isLoading={changingStandby}
                  />
                </div>
              ) : undefined}
              <Divider className="margin-top" />
              {isVehicleFleet ? (
                subProjectsIsLoading ? (
                  <CenteredContent className="margin-top" loading />
                ) : (
                  <div className="margin-top">
                    <div className="row space-between margin-bottom">
                      <Typography variant="h6">{t("vehicleFleet.info")}</Typography>
                      <Stack direction="row">
                        <Tooltip title={t("vehicleFleet.copyLink")} arrow>
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_FRONT_ROOT_URL}/enroll/${project.id}`,
                              );
                              enqueueSnackbar(t("vehicleFleet.linkIsCopied"), { variant: "success" });
                            }}>
                            <ContentCopy />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("vehicleFleet.tableSummaryExport")} arrow>
                          {exportingTableSummary ? (
                            <CenteredContent loadingSize={24} loading />
                          ) : (
                            <IconButton onClick={exportTableSummary}>
                              <TableChart />
                            </IconButton>
                          )}
                        </Tooltip>
                      </Stack>
                    </div>
                    {subProjects?.length > 0 ? (
                      subProjects.map((subProject) =>
                        subProject.vehicleLists?.map((vl) => (
                          <SubProjectAccordion vehicleList={vl} subProjectId={subProject.id} />
                        )),
                      )
                    ) : (
                      <Typography>{t("vehicleFleet.noVehicleFleetYet")}</Typography>
                    )}
                    <Divider className="margin-top" />
                  </div>
                )
              ) : undefined}
              <ProjectComments project={project} />
            </>
          )}
        </div>
        <div className="column-right big-margin-left">
          <IconButton style={{ marginBottom: 8 }} onClick={() => setUserOpen(!userOpen)}>
            {userOpen ? <Cancel /> : <AccountCircle />}
          </IconButton>
          {userOpen ? <ProjectUsers project={project} /> : undefined}
        </div>
      </div>
      <Modal open={operationsOpen} onClose={() => setOperationsOpen(false)} className="project-modal-root">
        <Paper className="project-modal-paper">
          <Typography variant="h6" className="margin-bottom">
            {t("operationsTwo")}
          </Typography>
          <OperationsViewer
            replaceOperations={replaceOperations}
            operations={(project.operations as Operation[]) || []}
            zipCode={project.client?.company?.zipCode || ""}
            sector={project.details?.sector}
            dontTouchOperations={dontTouchOperations}
            totalComputation={project.totalComputation}
          />
        </Paper>
      </Modal>
      <Modal open={affectObligedOpen} onClose={() => setAffectObligedOpen(false)} className="project-modal-root">
        <Paper className="project-modal-paper" style={{ minHeight: "inherit" }}>
          <Typography className="margin-bottom">{t("chooseObliged")}</Typography>
          <Select
            value={selectedObligedId}
            onChange={(e) => setSelectedObligedId(e.target.value)}
            variant="outlined"
            fullWidth>
            {activeObliged.map((o) => (
              <MenuItem value={o.id} key={`u_${o.id}`}>
                {o.name} ({t("capacityLeft")} {formatCapacity(o.capacityLeft || "")})
              </MenuItem>
            ))}
          </Select>
          <GdButton
            label={t("affectThisObliged")}
            onClick={affectProjectToObliged}
            fullWidth
            className="margin-top"
            disabled={selectedObligedId.length === 0 || selectedObligedId === project.obligedId}
            isLoading={affectingObliged}
          />
        </Paper>
      </Modal>
      <ProjectCancelModal onClose={() => setCancelOpen(false)} projectId={project.id} open={cancelOpen} />
      <AmountsAndDatesModal open={adminOpen} onClose={() => setAdminOpen(false)} project={project} />
      <ProjectDateModal open={projectDateOpen} onClose={() => setProjectDateOpen(false)} project={project} />
      {project.projectType === ProjectType.VehicleFleet ? (
        <Tooltip title={t("settings:exportObligedDataCSV")} arrow>
          <Fab color="primary" style={{ position: "absolute", bottom: 32, right: 48 }} onClick={exportPnceeCsv}>
            {exportingPnceeCsv ? <CircularProgress sx={{ color: "#FFF" }} /> : <FileUpload />}
          </Fab>
        </Tooltip>
      ) : undefined}
    </BaseCard>
  );
};

export default Project;
